export const TempusCustomer = () => ({
  comboboxType: "customerOptions",
  close: true,
  nextElementId: "getIssuesForTimesheetButton",
  initComboboxForCustomers() {
    this.fetchCustomerOptions()
    if (!this.customerSelectedForTimesheet.hasOwnProperty("key")) {
      this.customerSelectedForTimesheet = {
        key: 0,
        displayName: "Alle",
        name: "Alle",
        value: "Alle",
        id: 0,
      }
    }
  },

  setCustomerSelected(key) {
    this.customerSelectedForTimesheet = this.customerOptions[key]

    if (key != 0) {
      document.getElementById("combobox-customerOptions").value =
        this.customerSelectedForTimesheet.key +
        " - " +
        this.customerSelectedForTimesheet.displayName
    } else {
      document.getElementById("combobox-customerOptions").value =
        this.customerSelectedForTimesheet.displayName
    }
  },

  fetchCustomerOptions(options = []) {
    const max = 100
    let total = 0
    let query = "?startAt=" + options.length
    this.fetchGETSomething(
      "/field/" +
        this.customerCustomfield +
        "/context/" +
        this.customerContext +
        "/option",
      query,
    )
      .then((result) => {
        if (total == 0) {
          total = result.total
        }
        options.push(...result.values)
        if (result.total > options.length) {
          return this.fetchCustomerOptions(options)
        } else {
          return options
        }
      })
      .then((options) => {
        if (options) {
          this.customerOptions = {
            0: {
              key: 0,
              displayName: "Alle",
              name: "Alle",
              value: "Alle",
              id: 0,
            },
          }
          this.itemlists["customerOptions"] = [0]
          for (const option of options) {
            let optionNameAndKey = option.value.split("::")
            option["key"] = optionNameAndKey[1]
            option["name"] = optionNameAndKey[0]
            option["displayName"] = optionNameAndKey[0]

            this.customerOptions[option.key] = option
            this.itemlists["customerOptions"].push(option.key)
          }
          this.itemlistsActive["customerOptions"] =
            this.itemlists["customerOptions"]
        }
      })
      .catch((err) => console.error(err))
  },
})
