export const TempusProjectForTimesheet = () => ({
  comboboxType: "timesheets",
  nextElementId: "combobox-users",
  close: true,
  initProject() {
    this.fetchProjectsForTimesheets()
    this.projectSelectedTimesheet = {
      key: 0,
      displayName: "Alle",
      name: "Alle",
      value: "Alle",
      id: 0,
    }
  },

  setTimesheet(projectKey) {
    this.projectSelectedTimesheet = this.timesheets[projectKey]
    this.projectIssuesTimesheet = []
    this.projectWorklogsTimesheet = []
    document.getElementById("combobox-timesheets").value =
      this.projectSelectedTimesheet.key +
      "- " +
      this.projectSelectedTimesheet.name
  },

  fetchProjectsForTimesheets() {
    this.fetchGETSomething("/project/search", "&status=live")
      .then((result) => {
        /*
         {
            key: 0,
            displayName: "Alle",
            name: "Alle",
            value: "Alle",
            id: 0,
          },
        */

        if (result.values) {
          this.timesheets = {
            0: {
              key: 0,
              displayName: "Alle",
              name: "Alle",
              value: "Alle",
              id: 0,
            },
          }
          this.itemlists["timesheets"] = [0]
          for (const value of result.values) {
            this.timesheets[value.key] = value
            this.itemlists["timesheets"].push(value.key)
          }
        }
        this.itemlistsActive["timesheets"] = this.itemlists["timesheets"]
      })
      .catch((err) => console.log(err))
  },

  resetTimesheet() {
    this.projectIssuesTimesheet = []
    this.projectWorklogsTimesheet = []
    if (document.getElementById("dateFrom").value != "") {
      this.timesheetDateFrom = document.getElementById("dateFrom").value
    }
    if (document.getElementById("dateTo").value != "") {
      this.timesheetDateTo = document.getElementById("dateTo").value
    }
  },

  getIssues(issues = []) {
    const max = 100
    const startedAfterDate = this.timesheetDateFrom
    const startedBeforeDate = this.timesheetDateTo
    let worklogDateFrom = ""
    let worklogDateTo = ""
    if (startedAfterDate != "") {
      const startedAfter = Math.floor(new Date(startedAfterDate).getTime())
      worklogDateFrom = " worklogDate >= " + startedAfter
    }
    if (startedBeforeDate != "") {
      const startedBefore =
        Math.floor(new Date(startedBeforeDate).getTime()) + 24 * 3600000
      worklogDateTo = " and worklogDate <= " + startedBefore
    }
    let jqlForCustomer = ""
    if (
      this.customerSelectedForTimesheet &&
      this.customerSelectedForTimesheet.key != 0
    ) {
      jqlForCustomer =
        " cf[10068] = " + this.customerSelectedForTimesheet.id + " and "
    }
    let jqlForProject = ""
    if (
      this.projectSelectedTimesheet &&
      this.projectSelectedTimesheet.hasOwnProperty("key") &&
      this.projectSelectedTimesheet.key != 0
    ) {
      jqlForProject =
        " project  = " + this.projectSelectedTimesheet.key + " and "
    }
    const data = {
      expand: [],
      fields: [
        "parent",
        "summary",
        "issuetype",
        "customfield_10044",
        this.customerCustomfield,
      ],
      fieldsByKeys: false,
      jql: jqlForCustomer + jqlForProject + worklogDateFrom + worklogDateTo,
      maxResults: max,
      startAt: issues.length,
    }
    this.fetchPOSTPUTSomething("POST", "/search", data)
      .then((result) => {
        if (result.issues) {
          issues.push(...result.issues)
        }
        if (result.total > issues.length) {
          return this.getIssues(issues)
        } else {
          this.projectIssuesTimesheet = issues
          return issues
        }
      })
      .then(() => {
        this.getWorklogsForIssues(this.projectIssuesTimesheet)
      })
  },

  getWorklogsForIssues(issues) {
    const startedAfterDate = this.timesheetDateFrom
    const startedBeforeDate = this.timesheetDateTo
    // queryByDate still needed
    let queryByDate = ""
    if (startedAfterDate != "") {
      const startedAfter = Math.floor(new Date(startedAfterDate).getTime())
      queryByDate += "&startedAfter=" + startedAfter
    }
    if (startedBeforeDate != "") {
      const startedBefore =
        Math.floor(new Date(startedBeforeDate).getTime()) + 24 * 3600000
      queryByDate += "&startedBefore=" + startedBefore
    }
    issues.forEach((issue) => {
      this.collectWorklogs(issue.key, issue, queryByDate)
    })
  },

  collectWorklogs(issueKey, issue, queryByDate, worklogs = [], count = 0) {
    const max = 100
    const endpoint = "/issue/" + issueKey + "/worklog/?expand=properties"
    const query =
      "&maxResults=" + max + "&startAt=" + worklogs.length + queryByDate
    this.fetchGETSomething(endpoint, query).then((result) => {
      count += result.worklogs.length
      let worklogstmp = result.worklogs
      if (this.fetchNotChargedOnly) {
        worklogstmp = worklogstmp.filter(
          (worklog) => !(this.getWorklogProperty(worklog, "charged", 0) > 0),
        )
      }
      if (
        this.userSelectedForTimesheet &&
        this.userSelectedForTimesheet.key != "Alle"
      ) {
        worklogstmp = worklogstmp.filter(
          (worklog) =>
            worklog.author.displayName == this.userSelectedForTimesheet.key,
        )
      }
      worklogs.push(...worklogstmp)
      if (result.total > count) {
        return this.collectWorklogs(
          issueKey,
          issue,
          queryByDate,
          worklogs,
          count,
        )
      } else {
        issue["worklogs"] = worklogs
        this.projectWorklogsTimesheet.push(...[issue])
      }
    })
  },
})
