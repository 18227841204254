export const TempusUser = () => ({
  comboboxType: "users",
  close: true,
  nextElementId: "combobox-customerOptions",

  initializeUser() {
    this.fetchGETSomething("/user/search", "&query=$$$$user$$$$")
      .then((result) => {
        if (!result.err) {
          this.user = result[0]
        } else {
          this.user = {}
          this.showLogin = true
        }
      })
      .catch((err) => {
        console.error(err)
      })
  },

  initComboboxForUser() {
    this.fetchUsers()
    if (!this.userSelectedForTimesheet.hasOwnProperty("key")) {
      this.userSelectedForTimesheet = { key: "Alle", displayName: "Alle" }
    }
  },

  fetchUsers() {
    this.fetchGETSomething("/users/search")
      .then((result) => {
        this.users = { Alle: { key: "Alle", displayName: "Alle" } }
        this.itemlists["users"] = ["Alle"]
        if (result.length) {
          result = result.filter((user) => {
            return user.active && user.accountType == "atlassian"
          })
          for (const userItem of result) {
            userItem["key"] = userItem.displayName
            this.users[userItem.displayName] = userItem
            this.itemlists["users"].push(userItem.displayName)
          }
        }
        this.itemlistsActive["users"] = this.itemlists["users"]
      })
      .catch((err) => console.log(err))
  },

  setUserSelected(userKey) {
    this.userSelectedForTimesheet = this.users[userKey]
    // userKey == displayname
    document.getElementById("combobox-users").value = userKey
  },

  logout() {
    this.user = {}
  },
  emergencyLogout() {
    localStorage.clear()
    this.user = {}
  },
})
