export const TempusWorklog = () => ({
  initWorklog() {
    this.setWatchTracking()
    this.fetchWorklogTimer = setInterval(() => {
      const user = this.getUser()
      if (user.active) {
        this.getIssuesWithWorklogs()
        this.fetchTodaysWorklogs()
        clearInterval(this.fetchWorklogTimer)
        this.fetchWorklogTimer = null
      }
    }, 500)
  },

  saveWorklog(event) {
    const issueId = this.issueSelected.id
    const datetime = this.formatDateTime(event.target.dateTime.value)
    const time = this.formatTimeSpendFromInput(event.target.time.value)
    const comment = this.formatComment(event.target.comment.value)
    this.createWorklog(issueId, time, datetime, comment)
  },

  formatComment(comment) {
    comment = {
      content: [
        {
          content: [
            {
              text: comment,
              type: "text",
            },
          ],
          type: "paragraph",
        },
      ],
      type: "doc",
      version: 1,
    }
    return comment
  },

  getWorklogSummary() {
    const worklogs = this.getIssue().fields.worklog.worklogs
    let worklogSummary = 0
    worklogs.forEach((worklog) => {
      worklogSummary += worklog.timeSpentSeconds
    })
    return this.formatSeconds(worklogSummary)
  },

  prepareDate() {
    const newDate = new Date()
    return newDate.toLocaleString()
  },

  getUser() {
    if (this.user === undefined) {
      this.user = {}
    }
    return this.user
  },

  deleteWorklog(issueKey, worklogId) {
    this.fetchDELSomething("/issue/" + issueKey + "/worklog/" + worklogId).then(
      (response) => {
        if (response.err) {
          window.location.reload()
        }
        this.removeOneWorklogFromActiveView(issueKey, worklogId)
      },
    )
  },

  removeOneWorklogFromActiveView(issueKey, worklogId) {
    this.personalWorklogsTimesheet.forEach((element) => {
      if (element.key == issueKey) {
        element.worklogs = element.worklogs.filter(
          (worklog) => worklog.id != worklogId,
        )
      }
    })
  },

  getTrackingStatus() {
    return this.trackingStatus
  },

  createWorklog(issueId, time, datetime, comment, callback = null) {
    this.fetchPOSTPUTSomething("POST", "/issue/" + issueId + "/worklog", {
      comment: comment,
      started: datetime,
      timeSpent: time,
    }).then((response) => {
      if (response.err) {
        window.location.reload()
      }
      if (issueId == this.issueSelected.id) {
        this.fetchIssueData(this.issueSelected.id)
      }

      if (callback !== null) {
        callback(response)
      }
      // this.fetchTodaysWorklogs()
      this.getIssuesWithWorklogs()
      this.fetchTodaysWorklogs()
      return response
    })
  },

  updateWorklogFromEdit(issueId, worklogId, formId) {
    const datetime = this.formatDateTime(
      document.querySelector("#" + formId + ' input[name="dateTime"]').value,
    )
    const time = document
      .querySelector("#" + formId + ' input[name="time"]')
      .value.split(":")
    const timeSpentSeconds = parseInt(time[0]) * 3600 + parseInt(time[1]) * 60
    const comment = this.formatComment(
      document.querySelector("#" + formId + ' input[name="comment"]').value,
    )
    this.updateWorklog(
      issueId,
      worklogId,
      timeSpentSeconds,
      comment,
      null,
      datetime,
    )
  },

  updateWorklog(
    issueId,
    worklogId,
    time = 0,
    comment = null,
    callback = null,
    datetime = null,
  ) {
    const timeTracked = time > 60 ? time : 60
    const json = {
      comment: comment,
      timeSpentSeconds: timeTracked,
    }
    if (datetime) {
      json["started"] = datetime
    }
    this.fetchPOSTPUTSomething(
      "PUT",
      "/issue/" + issueId + "/worklog/" + worklogId,
      { ...json },
    ).then((response) => {
      if (response.err) {
        window.location.reload()
      }
      if (issueId == this.issueSelected.id) {
        this.fetchIssueData(issueId)
      }
      if (callback !== null) {
        callback(response)
      }
      // this.fetchTodaysWorklogs()
      this.getIssuesWithWorklogs()
      this.fetchTodaysWorklogs()
      return response
    })
  },

  toggleNewTracking() {
    if (this.activeWorklog === null) {
      this.setTrackingStatus()
    } else {
      // call it twice, once to end the active tracking and
      // once to start the new one
      this.setTrackingStatus()
      this.setTrackingStatus()
    }
  },

  setTrackingStatus() {
    if (this.activeWorklog === null) {
      const comment = document.getElementById("comment-active-tracking").value
      this.createWorklog(
        this.issueSelected.id,
        "0h 0m",
        this.formatDateTime(new Date().toISOString()),
        this.formatComment((comment != "" && comment) || "live tracking"),
        (response) => {
          if (!response.err) {
            this.trackingStatus = this.trackingStatusActive
            response["issueKey"] = this.issueSelected.key
            this.activeWorklog = response
            this.activeWorklogStart = Date.now()
            this.activeWorklogSeconds = 0
            this.activeWorklogTimeForDisplay = "-"
            this.setWatchTracking()
          }
        },
      )
    } else {
      this.updateWorklog(
        this.activeWorklog.issueId,
        this.activeWorklog.id,
        Math.round((Date.now() - this.activeWorklogStart) / 1000),
        this.formatComment(
          this.activeWorklog.comment.content[0].content[0].text,
        ),
        (response) => {
          if (response.id == this.activeWorklog.id) {
            document.getElementById("comment-active-tracking").value = ""
            this.trackingStatus = this.trackingStatusOff
            this.activeWorklog = null
            this.activeWorklogStart = 0
            this.activeWorklogSeconds = 0
            this.setWatchTracking()
          } else {
            this.trackingStatus = this.trackingStatusError
          }
        },
      )
    }
  },

  setWatchTracking() {
    if (this.activeWorklog !== null) {
      this.worklogTimer = setInterval(() => {
        this.activeWorklogTimeForDisplay = this.formatSeconds(
          (Date.now() - this.activeWorklogStart) / 1000,
        )
        document.getElementsByTagName("title")[0].innerHTML =
          this.activeWorklogTimeForDisplay
      }, 2000)
    } else {
      clearInterval(this.worklogTimer)
      document.getElementsByTagName("title")[0].innerHTML = "Tempus"
      this.worklogTimer = null
    }
  },

  fetchTodaysWorklogs() {
    let query = ""
    let seconds = 0
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (this.user.active) {
      query =
        "&properties=fields.worklog&jql=" +
        encodeURIComponent(
          'worklogAuthor = "' +
            this.user.displayName +
            '" AND worklogDate >= ' +
            Math.floor(today.getTime()) +
            " order by updated ASC",
        )
      this.fetchGETSomething("/search", query)
        .then(async (result) => {
          for (const fullIssue of result.issues) {
            await this.fetchGETSomething(
              "/issue/" + fullIssue.id + "/worklog",
              "&startedAfter=" + Math.floor(today.getTime()),
            ).then((result) => {
              for (const worklog of result.worklogs) {
                if (worklog.author.displayName == this.user.displayName) {
                  seconds += worklog.timeSpentSeconds
                }
              }
            })
          }
        })
        .then(() => {
          this.secondsSpentToday = seconds
          this.formattedSecondsToday = this.formatSeconds(
            this.secondsSpentToday,
          )
        })
        .catch((err) => console.log(err))
    }
  },

  resetIssuesWithWorklogs() {
    document.getElementById("worklogDateFrom").value = ""
    document.getElementById("worklogDateTo").value = ""
    this.personalIssuesTimesheet = []
    this.personalWorklogsTimesheet = []
    this.getIssuesWithWorklogs()
  },

  getIssuesWithWorklogs(issues = []) {
    const max = 100
    const startedAfterDate = document.getElementById("worklogDateFrom").value
    const startedBeforeDate = document.getElementById("worklogDateTo").value
    if (issues.length == 0) {
      this.personalIssuesTimesheet = []
      this.personalWorklogsTimesheet = []
    }

    let worklogDateTo = ""

    let startedAfter = 0
    if (startedAfterDate != "") {
      startedAfter = Math.floor(new Date(startedAfterDate).getTime())
    } else {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      startedAfter = Math.floor(today.getTime()) - 72 * 3600000
    }
    let worklogDateFrom = " and worklogDate >= " + startedAfter

    if (startedBeforeDate != "") {
      const startedBefore = Math.floor(new Date(startedBeforeDate).getTime()) // +24*3600000
      worklogDateTo = " and worklogDate <= " + startedBefore
    }

    const data = {
      expand: [],
      fields: ["parent", "summary", "issuetype", "worklog"],
      fieldsByKeys: false,
      jql:
        "worklogAuthor = '" +
        this.user.displayName +
        "'" +
        worklogDateFrom +
        worklogDateTo,
      maxResults: max,
      startAt: issues.length,
    }
    this.fetchPOSTPUTSomething("POST", "/search", data)
      .then((result) => {
        issues.push(...result.issues)
        if (result.total > issues.length) {
          return this.getIssuesWithWorklogs(issues)
        } else {
          this.personalIssuesTimesheet = issues
          return issues
        }
      })
      .then(() => {
        this.getWorklogsForIssues(this.personalIssuesTimesheet)
      })
  },

  getWorklogsForIssues(issues) {
    const startedAfterDate = document.getElementById("worklogDateFrom").value
    const startedBeforeDate = document.getElementById("worklogDateTo").value
    // queryByDate still needed
    let queryByDate = ""
    let startedAfter = ""
    let startedBefore = ""
    if (startedAfterDate != "") {
      startedAfter = Math.floor(new Date(startedAfterDate).getTime())
      queryByDate += "&startedAfter=" + startedAfter
    } else {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      startedAfter = Math.floor(today.getTime()) - 72 * 3600000
      queryByDate += "&startedAfter=" + startedAfter
    }

    if (startedBeforeDate != "") {
      startedBefore =
        Math.floor(new Date(startedBeforeDate).getTime()) + 24 * 3600000
      queryByDate += "&startedBefore=" + startedBefore
    }
    issues.forEach((issue) => {
      this.collectWorklogs(issue.key, issue, queryByDate)
    })
  },

  // need count, because filtert worklogs.length is lower then original worklogs.length
  collectWorklogs(issueKey, issue, queryByDate, worklogs = [], count = 0) {
    const max = 100
    const endpoint = "/issue/" + issueKey + "/worklog/?expand=properties"
    const query =
      "&maxResults=" + max + "&startAt=" + worklogs.length + queryByDate
    this.fetchGETSomething(endpoint, query).then((result) => {
      count += result.worklogs.length
      const worklogstmp = result.worklogs.filter(
        (worklog) => worklog.author.displayName == this.user.displayName,
      )
      worklogs.push(...worklogstmp)
      if (result.total > count) {
        return this.collectWorklogs(
          issueKey,
          issue,
          queryByDate,
          worklogs,
          count,
        )
      } else {
        issue["worklogs"] = this.getWorklogsSorted(worklogs)
        this.personalWorklogsTimesheet.push(...[issue])
      }
    })
  },

  getWorklogsSorted(worklogs) {
    return worklogs.sort((a, b) => {
      const wlA = new Date(a.started)
      const wlB = new Date(b.started)
      if (wlA < wlB) return 1
      if (wlA > wlB) return -1
      return 0
    })
  },

  setSelectedWorklogsCharged(status) {
    const toChange = document.querySelectorAll(
      'input[name="worklogToCharge[]"]:checked',
    )
    toChange.forEach((check) => {
      const issueKeyWorklogId = check.value.split("::")
      this.fetchPOSTPUTSomething(
        "PUT",
        "/issue/" +
          issueKeyWorklogId[0] +
          "/worklog/" +
          issueKeyWorklogId[1] +
          "/properties/charged",
        { value_alone: status },
      )
    })
    document.querySelector("#getIssuesForTimesheetButton").click()
  },

  getWorklogProperty(worklog, propertyKey, defaultValue = null) {
    if (worklog.hasOwnProperty("properties")) {
      const property = worklog.properties.filter((element) => {
        if (element["key"] == propertyKey) {
          return element["value"]
        }
      })
      return property && property.length
        ? property[0].value
        : defaultValue
          ? defaultValue
          : false
    }
    return defaultValue ? defaultValue : false
  },
})
